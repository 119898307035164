






























































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppEmpty from '@/components/AppEmpty.vue';
import AppPanel from '@/components/AppPanel.vue';
import AppPanelItem from '@/components/AppPanelItem.vue';
import AppListItem from '@/components/AppListItem.vue';
import ROUTES from '@/graphql/queries/BaseRoutes.graphql';
import BASE_ROUTE_DELETE from '@/graphql/mutations/BaseRouteDelete.graphql';
import formatDate from '@/utils/formatDate';
import pluralNumber from '@/utils/pluralNumber';
import { BaseRoute, BaseRoutesQuery } from '@/types/schema';

export default Vue.extend({
  name: 'AdminRoutes',
  components: {
    AppMain,
    AppEmpty,
    AppPanel,
    AppPanelItem,
    AppListItem,
  },
  data() {
    return {
      routes: [] as BaseRoutesQuery['routes'],
    };
  },
  methods: {
    formatDate,
    getSubtitle(route: BaseRoute) {
      const stops = pluralNumber(route.pickupLocations.length, 'Stop');
      const miles = pluralNumber(route.miles, 'Mile');
      return `${stops} ${miles}`;
    },
    async deleteRoute(id: BaseRoute['id']) {
      if (!confirm("Are you sure? This action can't be undone.")) return;

      try {
        await this.$apollo.mutate({
          mutation: BASE_ROUTE_DELETE,
          variables: {
            input: { id },
          },
        });
        this.$apollo.queries.routes.refetch();
        this.$notify({
          text: 'Route deleted!',
          type: 'success',
          duration: 6000,
        });
      } catch (error) {
        this.$notify({
          text: error.graphQLErrors[0].message,
          type: 'error',
          duration: 6000,
        });
      }
    },
  },
  apollo: {
    routes: {
      query: ROUTES,
      fetchPolicy: 'cache-and-network',
    },
  },
});
